import {useEffect, useState} from "react";
import {useSearchParams} from "react-router-dom";
import {connect, useSelector} from "react-redux";
import { Switch } from '@headlessui/react'
import Loading from "../../common/Loading";
import * as api from "../../../api/api";
import {resultBoolean, convertBoolean} from "../../common/Global";
import {getSportsBaseList} from "../../../api/api";
import {CModal} from "@coreui/react";
import UserPointModal from "./UserPointModal";
import UserMoneyModal from "./UserMoneyModal";

const UserDetail = (props) => {
    const {user} = useSelector((props) => props.account)
    const [loading, setLoading] = useState(false)
    const [searchValue, setSearchValue] = useState("")
    const [userInfo, setUserInfo] = useState({
        companyCode: "", companyType: "", parentId: "", userType: "6000",
        userId: "", userLevel: 1, userName: "", password: "", nickName: "", mobileNo: "", recommendCode: "",
        bankAccount: "", bankName: "", bankOwner: "",
        exchangePass: "", exchangeTime: 0,
        filler1: "",
        recommendYn: 1, bettingYn: 1, moneyRetYn: 1, pointRetYn: 1, transferPointYn: 1, blackYn: 0,
        status: 1, useYn: 1,
        upperUserList: [],
        userSportsSetting: {},
        userSportsPer: {}
    })

    const [sportsSetting, setSportsSetting] = useState({
        soccerYn: 1,
        footballYn: 1,
        boxingYn: 1,
        tennisYn: 1,
        baseballYn: 1,
        icehockeyYn: 1,
        basketballYn: 1,
        handballYn: 1,
        volleyballYn: 1,
        tabletennisYn: 1,
        esportsYn: 1,
    })

    const [defaultRolling, setDefaultRolling] = useState(0)
    const [defaultLosing, setDefaultLosing] = useState(0)
    const [sportsPer, setSportsPer] = useState({
        soccerRolling: 0.0,
        soccerLosing: 0.0,
        footballRolling: 0.0,
        footballLosing: 0.0,
        boxingRolling: 0.0,
        boxingLosing: 0.0,
        tennisRolling: 0.0,
        tennisLosing: 0.0,
        baseballRolling: 0.0,
        baseballLosing: 0.0,
        icehockeyRolling: 0.0,
        icehockeyLosing: 0.0,
        basketballRolling: 0.0,
        basketballLosing: 0.0,
        handballRolling: 0.0,
        handballLosing: 0.0,
        volleyballRolling: 0.0,
        volleyballLosing: 0.0,
        tabletennisRolling: 0.0,
        tabletennisLosing: 0.0,
        esportsRolling: 0.0,
        esportsLosing: 0.0,
    })

    const [pointModal, setPointModal] = useState({visible: false, type: "0"})
    const [moneyModal, setMoneyModal] = useState({visible: false, type: "0"})

    const [searchParam] = useSearchParams()
    const userId = searchParam.get("userId") ? searchParam.get("userId") : ""
    const procUserId = searchParam.get("procUserId") ? searchParam.get("procUserId") : ""

    useEffect(() => {
        search()
    }, [])

    const search = () => {
        setLoading(true)
        api.getGUser(userId).then(result => {
            const {data, status, statusText} = result;
            if (status === 200) {
                setUserInfo(data)
                console.log("### data :: ", data)

                if (data.userSportsSetting !== null) {
                    setSportsSetting(data.userSportsSetting)
                }

                if (data.userSportsPer !== null) {
                    setSportsPer(data.userSportsPer)
                }
            }
        })
            .catch(ex => {
                window.alert(ex.message)
            })
            .finally(() => {
                setLoading(false)
            })
    }

    const getSportsBaseList = () => {
        api.getSportsBaseList().then(result => {
            const {data, status, statusText} = result
            if (status === 200) {
                setSportsSetting(data)
            }
        })
    }

    const handleUserInfo = (e) => {
        const {name, value} = e.target;
        setUserInfo({
            ...userInfo,
            [name]: value
        })
    }

    const handleUserGameSetting = (e) => {
        const {name, value} = e.target
    }

    const save = () => {
        if (window.confirm("저장하시겠어요?")) {
            // setLoading(true)
            console.log("### userInfo :: ", userInfo)
            setLoading(true)
            api.putGUser(userInfo).then(result => {
                const {data, status, statusText} = result;
                if (status === 200) {
                    window.alert("정상적으로 처리되었습니다.")
                }
            })
                .catch(ex => {
                    window.alert(ex.message)
                })
                .finally(() => {
                    setLoading(false)
                })
        }
    }

    const saveSportsSetting = () => {
        if (window.confirm("저장하시겠어요?")) {
            const params = {
                seq: sportsSetting.seq ? sportsSetting.seq : -1,
                userId: userId,
                ...sportsSetting
            }

            console.log("### setting :: ", params)
            setLoading(true)
            api.putSportsSetting(params).then(result => {
                const {data, status, statusText} = result;
                if (status === 200) {
                    window.alert("정상적으로 처리되었습니다.")
                }
            })
                .catch(ex => {
                    window.alert(ex.message)
                })
                .finally(() => {
                    setLoading(false)
                })
        }
    }

    const saveSportsPer = () => {
        if (window.confirm("저장하시겠어요?")) {
            const params = {
                seq: sportsPer.seq ? sportsPer.seq : -1,
                userId: userId,
                ...sportsPer
            }

            console.log("### per :: ", sportsPer)
            setLoading(true)
            api.putSportsPer(params).then(result => {
                const {data, status, statusText} = result;
                if (status === 200) {
                    window.alert("정상적으로 처리되었습니다.")
                }
            })
                .catch(ex => {
                    window.alert(ex.message)
                })
                .finally(() => {
                    setLoading(false)
                })
        }
    }

    const onButtonMenu = (value) => {
        switch (value) {
            case 0:
                setPointModal({visible: true, type: "0"})
                break;
            case 1:
                setPointModal({visible: true, type: "1"})
                break;
            case 2:
                setMoneyModal({visible: true, type: "0"})
                break;
            case 3:
                setMoneyModal({visible: true, type: "1"})
                break;
            case 4:
                if (window.opener) {
                    window.opener.postMessage(userId + "," + "betting", window.location.origin)
                }
                break;
            case 5:
                if (window.opener) {
                    window.opener.postMessage(userId + "," + "deposit", window.location.origin)
                }

                break;
            case 6:
                if (window.opener) {
                    window.opener.postMessage(userId + "," + "withdraw", window.location.origin)
                }

                break;
            case 7:
                if (window.opener) {
                    window.opener.postMessage(userId + "," + "point", window.location.origin)
                }

                break;
            case 8:
                if (window.opener) {
                    window.opener.postMessage(userId + "," + "loginLog", window.location.origin)
                }

                break;
            case 9:

                break;
            case 10:

                break;
            case 11:

                break;
            case 12:

                break;
            case 13:

                break;
        }
    }

    const procMoney = (money) => {
        const params = {
            ...money,
            procUserId: procUserId
        }

        setLoading(true)
        api.postMoney(params).then(result => {
            const {data, status, statusText} = result
            if (status === 200) {
                window.alert("정상적으로 처리되었습니다.")
                return
            }
        })
            .catch(ex => {
                window.alert(ex.message)
            })
            .finally(() => {
                setLoading(false)
            })
    }

    const procPoint = (point) => {
        const params = {
            ...point,
            procUser: procUserId
        }

        console.log("### point :: ", params)

        setLoading(true)
        api.postPoint(params).then(result => {
            const {data, status, statusText} = result
            if (status === 200) {
                window.alert("정상적으로 처리되었습니다.")
                return
            }
        })
            .catch(ex => {
                window.alert(ex.message)
            })
            .finally(() => {
                setLoading(false)
            })
    }

    return (
        <div className={"flex flex-col p-2 gap-1"}>
            {loading ? <Loading /> : null}
            <p className={"text-md font-bold"}>회원 관리 > 회원 상세</p>
            <div className={"flex flex-row gap-1"}>
                <button className={"text-sm bg-gray-800 text-white px-2"} onClick={() => onButtonMenu(0)}>포인트 지급</button>
                <button className={"text-sm bg-gray-800 text-white px-2"} onClick={() => onButtonMenu(1)}>포인트 회수</button>
                <button className={"text-sm bg-gray-800 text-white px-2"} onClick={() => onButtonMenu(2)}>보유금 지급</button>
                <button className={"text-sm bg-gray-800 text-white px-2"} onClick={() => onButtonMenu(3)}>보유금 회수</button>
                <button className={"text-sm bg-gray-800 text-white px-2"} onClick={() => onButtonMenu(4)}>배팅 내역</button>
                <button className={"text-sm bg-gray-800 text-white px-2"} onClick={() => onButtonMenu(5)}>입금 내역</button>
                <button className={"text-sm bg-gray-800 text-white px-2"} onClick={() => onButtonMenu(6)}>출금 내역</button>
                <button className={"text-sm bg-gray-800 text-white px-2"} onClick={() => onButtonMenu(7)}>포인트 내역</button>
                <button className={"text-sm bg-gray-800 text-white px-2"} onClick={() => onButtonMenu(8)}>접속 로그</button>
                <button className={"text-sm bg-gray-800 text-white px-2"} onClick={() => onButtonMenu(9)}>쪽지 전송</button>
                <button className={"text-sm bg-gray-800 text-white px-2"} onClick={() => onButtonMenu(10)}>쪽지 내역</button>
                <button className={"text-sm bg-gray-800 text-white px-2"} onClick={() => onButtonMenu(11)}>파트너 변경</button>
                <button className={"text-sm bg-gray-800 text-white px-2"} onClick={() => onButtonMenu(12)}>일별 통계</button>
                <button className={"text-sm bg-gray-800 text-white px-2"} onClick={() => onButtonMenu(13)}>정보 수정 이력</button>
            </div>
            <div className={"border mt-1"}>
                <div className={"flex flex-row p-2 gap-2"}>
                    <span className={"text-sm font-bold"}>회원 아이디</span>
                    <input className={"text-sm border px-2"} value={userId} disabled={true}/>
                    <button className={"bg-gray-700 px-3 rounded text-white text-sm"} onClick={search}>조회</button>
                </div>
            </div>
            <div className={"flex flex-col gap-2"}>
                <div className={"flex flex-col"}>
                    <div className={"flex flex-row justify-between items-center bg-gray-100 p-2"}>
                        <span className={"text-md font-bold"}>유저정보</span>
                        <button className={"rounded text-xs text-white bg-gray-800 px-2 py-1"} onClick={save}>저장</button>
                    </div>
                    <table className={"table-auto border-1"}>
                        <tbody>
                        <tr className={"border-1"}>
                            <td className={"w-[140px]"}>
                                <span className={"text-sm font-bold"}>아이디</span>
                            </td>
                            <td>
                                <span className={"text-sm font-bold"}>{userInfo.userId}</span>
                            </td>
                            <td className={"w-[140px]"}>
                                <span className={"text-sm font-bold"}>휴대폰번호</span>
                            </td>
                            <td>
                                <input name={"mobileNo"}  className={"rounded border-1 px-2 text-sm"} value={userInfo.mobileNo} onChange={handleUserInfo}/>
                            </td>
                        </tr>
                        <tr className={"border-1"}>
                            <td className={"w-[140px]"}>
                                <span className={"text-sm font-bold"}>닉네임</span>
                            </td>
                            <td>
                                <input name={"nickName"}  className={"rounded border-1 px-2 text-sm"} value={userInfo.nickName} onChange={handleUserInfo}/>
                            </td>
                            <td className={"w-[140px]"}>
                                <span className={"text-sm font-bold"}>은행</span>
                            </td>
                            <td>
                                <span className={"text-sm"}>{userInfo.bankName}</span>
                            </td>
                        </tr>
                        <tr className={"border-1"}>
                            <td className={"w-[140px]"}>
                                <span className={"text-sm font-bold"}>이름</span>
                            </td>
                            <td>
                                <input name={"userName"}  className={"rounded border-1 px-2 text-sm"} value={userInfo.userName} onChange={handleUserInfo}/>
                            </td>
                            <td className={"w-[140px]"}>
                                <span className={"text-sm font-bold"}>계좌번호</span>
                            </td>
                            <td>
                                <span className={"text-sm"}>{userInfo.bankAccount}</span>
                            </td>
                        </tr>
                        <tr className={"border-1"}>
                            <td className={"w-[140px]"}>
                                <span className={"text-sm font-bold"}>비밀번호</span>
                            </td>
                            <td>
                                <input name={"password"} className={"rounded border-1 px-2 text-sm"} type={"password"} value={userInfo.password} onChange={handleUserInfo}/>
                            </td>
                            <td className={"w-[140px]"}>
                                <span className={"text-sm font-bold"}>회원레벨</span>
                            </td>
                            <td>
                                <span className={"text-sm"}>{userInfo.userLevel}</span>
                            </td>
                        </tr>
                        <tr className={"border-1"}>
                            <td className={"w-[140px]"}>
                                <span className={"text-sm font-bold"}>가입코드</span>
                            </td>
                            <td>
                                <input name={"recommendCode"}  className={"rounded border-1 px-2 text-sm"} value={userInfo.recommendCode} onChange={handleUserInfo}/>
                            </td>
                            <td className={"w-[140px]"}>
                                <span className={"text-sm font-bold"}>가입일</span>
                            </td>
                            <td>
                                <span className={"text-sm"}>{userInfo.instTime}</span>
                            </td>
                        </tr>
                        <tr className={"border-1"}>
                            <td className={"w-[140px]"}>
                                <span className={"text-sm font-bold"}>추천인 허용</span>
                            </td>
                            <td>
                                <Switch
                                    onChange={value => {
                                        setUserInfo({
                                            ...userInfo,
                                            recommendYn: convertBoolean(value)
                                        })
                                    }}
                                    checked={convertBoolean(userInfo.recommendYn)}
                                    className="group relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent bg-gray-200 transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:ring-offset-2 data-[checked]:bg-indigo-600">
                                    <span
                                        aria-hidden="true"
                                        className="pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out group-data-[checked]:translate-x-5"
                                    />
                                </Switch>
                            </td>
                            <td className={"w-[140px]"}>
                                <span className={"text-sm font-bold"}>마지막 로그인</span>
                            </td>
                            <td>
                                <span className={"text-sm"}>{userInfo.lastLoginHistory ? userInfo.lastLoginHistory.instTime : ""}</span>
                            </td>
                        </tr>
                        <tr className={"border-1"}>
                            <td className={"w-[140px]"}>
                                <span className={"text-sm font-bold"}>추천인 보유 머니<br/>지급 회수 권한</span>
                            </td>
                            <td>
                                <Switch
                                    onChange={value => {
                                        setUserInfo({
                                            ...userInfo,
                                            moneyRetYn: convertBoolean(value)
                                        })
                                    }}
                                    checked={convertBoolean(userInfo.moneyRetYn)}
                                    className="group relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent bg-gray-200 transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:ring-offset-2 data-[checked]:bg-indigo-600">
                                    <span
                                        aria-hidden="true"
                                        className="pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out group-data-[checked]:translate-x-5"
                                    />
                                </Switch>
                            </td>
                            <td className={"w-[140px]"}>
                                <span className={"text-sm font-bold"}>마지막 로그인 IP</span>
                            </td>
                            <td>
                                <span>{userInfo.lastLoginHistory ? userInfo.lastLoginHistory.ip : ""}</span>
                            </td>
                        </tr>
                        <tr className={"border-1"}>
                            <td className={"w-[140px]"}>
                                <span className={"text-sm font-bold"}>추천인 보유 포인트<br />지급 회수 권한</span>
                            </td>
                            <td>
                                <Switch
                                    onChange={value => {
                                        setUserInfo({
                                            ...userInfo,
                                            pointRetYn: convertBoolean(value)
                                        })
                                    }}
                                    checked={resultBoolean(userInfo.pointRetYn)}
                                    className="group relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent bg-gray-200 transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:ring-offset-2 data-[checked]:bg-indigo-600">
                                    <span
                                        aria-hidden="true"
                                        className="pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out group-data-[checked]:translate-x-5"
                                    />
                                </Switch>
                            </td>
                            <td className={"w-[140px]"}>
                                <span className={"text-sm font-bold"}>로그인 가능 여부</span>
                            </td>
                            <td>
                                <Switch
                                    onChange={value => {
                                        setUserInfo({
                                            ...userInfo,
                                            status: convertBoolean(value)
                                        })
                                    }}
                                    checked={resultBoolean(userInfo.status)}
                                    className="group relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent bg-gray-200 transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:ring-offset-2 data-[checked]:bg-indigo-600">
                                    <span
                                        aria-hidden="true"
                                        className="pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out group-data-[checked]:translate-x-5"
                                    />
                                </Switch>
                            </td>
                        </tr>
                        <tr className={"border-1"}>
                            <td className={"w-[140px]"}>
                                <span className={"text-sm font-bold"}>{"환전 비밀번호"}</span>
                            </td>
                            <td>
                                <input className={"rounded border-1 px-2 text-sm"} type={"password"} value={userInfo.exchangePass}/>
                            </td>
                            <td className={"w-[140px]"}>
                                <span className={"text-sm font-bold"}>정지 설정</span>
                            </td>
                            <td>
                                <Switch
                                    onChange={value => {
                                        setUserInfo({
                                            ...userInfo,
                                            blackYn: convertBoolean(value)
                                        })
                                    }}
                                    checked={resultBoolean(userInfo.blackYn)}
                                    className="group relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent bg-gray-200 transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:ring-offset-2 data-[checked]:bg-indigo-600">
                                    <span
                                        aria-hidden="true"
                                        className="pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out group-data-[checked]:translate-x-5"/>
                                </Switch>
                            </td>
                        </tr>
                        <tr className={"border-1"}>
                            <td className={"w-[140px]"}>
                                <span className={"text-sm font-bold"}>{"상위 파트너 리스트"}</span>
                            </td>
                            <td>
                                {userInfo.upperUserList.length > 0 &&
                                <div className={"flex flex-row gap-1 items-center"}>
                                    {userInfo.upperUserList.map(item => {
                                        return <span className={"text-xs"}><span className={"text-sm font-bold"}>{item}</span> >></span>
                                    })}
                                </div>
                                }
                            </td>
                            <td className={"w-[140px]"}>
                                <span className={"text-sm font-bold"}>배팅 가능</span>
                            </td>
                            <td>
                                <Switch
                                    onChange={value => {
                                        setUserInfo({
                                            ...userInfo,
                                            bettingYn: convertBoolean(value)
                                        })
                                    }}
                                    checked={resultBoolean(userInfo.bettingYn)}
                                    className="group relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent bg-gray-200 transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:ring-offset-2 data-[checked]:bg-indigo-600">
                                    <span
                                        aria-hidden="true"
                                        className="pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out group-data-[checked]:translate-x-5"/>
                                </Switch>
                            </td>
                        </tr>
                        <tr className={"border-1"}>
                            <td className={"w-[140px]"}>

                            </td>
                            <td>

                            </td>
                            <td className={"w-[140px]"}>
                                <span className={"text-sm font-bold"}>포인트 전환</span>
                            </td>
                            <td>
                                <Switch
                                    onChange={value => {
                                        setUserInfo({
                                            ...userInfo,
                                            transferPointYn: convertBoolean(value)
                                        })
                                    }}
                                    checked={resultBoolean(userInfo.transferPointYn)}
                                    className="group relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent bg-gray-200 transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:ring-offset-2 data-[checked]:bg-indigo-600">
                                    <span
                                        aria-hidden="true"
                                        className="pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out group-data-[checked]:translate-x-5"/>
                                </Switch>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>
                <div className={"flex flex-col"}>
                    <div className={"flex flex-row justify-between items-center bg-gray-100 p-2"}>
                        <span className={"text-md font-bold"}>메모</span>
                    </div>
                    <div className={"flex h-[100px]"}>
                        <textarea name={"filler1"} className={"flex-1 p-1 border-1 text-sm"} value={userInfo.filler1} onChange={handleUserInfo} />
                    </div>
                </div>
                <div className={"flex flex-col"}>
                    <div className={"flex flex-row items-center gap-2"}>
                        {/*<span className={"p-1 rounded border-2 border-indigo-500 text-sm font-bold"}>게임별 베팅설정(카지노)</span>*/}
                        {/*<span className={"p-1 rounded border-2 border-indigo-500 text-sm font-bold"}>게임별 베팅설정(슬롯)</span>*/}
                        {/*<span className={"p-1 rounded border-2 border-indigo-500 text-sm font-bold"}>게임별 베팅설정(미니게임)</span>*/}
                        <span className={"p-1 rounded-t border-t-2 border-x-2 border-indigo-500 text-sm font-bold"}>게임별 베팅설정(스포츠)</span>
                        {/*<span className={"p-1 rounded border-2 text-sm font-bold"}>받치기 요율 설정</span>*/}
                    </div>
                    <div className={"flex flex-row justify-between items-center bg-gray-100 p-2"}>
                        <span className={"text-md font-bold"}>게임목록</span>
                        <button className={"rounded text-xs text-white bg-gray-800 px-2 py-1"} onClick={saveSportsSetting}>저장</button>
                    </div>
                    <div className={"grid grid-cols-5 gap-2 p-2 border-1"}>
                        <div className={"flex flex-row gap-2 items-center"}>
                            <span className={"w-[80px] text-sm font-bold"}>축구</span>
                            <Switch
                                onChange={value => {
                                    setSportsSetting({
                                        ...sportsSetting,
                                        soccerYn: convertBoolean(value)
                                    })
                                }}
                                checked={convertBoolean(sportsSetting.soccerYn)}
                                className="group relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent bg-gray-200 transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:ring-offset-2 data-[checked]:bg-indigo-600">
                                    <span
                                        aria-hidden="true"
                                        className="pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out group-data-[checked]:translate-x-5"/>
                            </Switch>
                        </div>
                        <div className={"flex flex-row gap-2 items-center"}>
                            <span className={"w-[80px] text-sm font-bold"}>미식축구</span>
                            <Switch
                                onChange={value => {
                                    setSportsSetting({
                                        ...sportsSetting,
                                        footballYn: convertBoolean(value)
                                    })
                                }}
                                checked={convertBoolean(sportsSetting.footballYn)}
                                className="group relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent bg-gray-200 transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:ring-offset-2 data-[checked]:bg-indigo-600">
                                    <span
                                        aria-hidden="true"
                                        className="pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out group-data-[checked]:translate-x-5"/>
                            </Switch>
                        </div>
                        <div className={"flex flex-row gap-2 items-center"}>
                            <span className={"w-[80px] text-sm font-bold"}>복싱/UFC</span>
                            <Switch
                                onChange={value => {
                                    setSportsSetting({
                                        ...sportsSetting,
                                        boxingYn: convertBoolean(value)
                                    })
                                }}
                                checked={convertBoolean(sportsSetting.boxingYn)}
                                className="group relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent bg-gray-200 transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:ring-offset-2 data-[checked]:bg-indigo-600">
                                    <span
                                        aria-hidden="true"
                                        className="pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out group-data-[checked]:translate-x-5"/>
                            </Switch>
                        </div>
                        <div className={"flex flex-row gap-2 items-center"}>
                            <span className={"w-[80px] text-sm font-bold"}>테니스</span>
                            <Switch
                                onChange={value => {
                                    setSportsSetting({
                                        ...sportsSetting,
                                        tennisYn: convertBoolean(value)
                                    })
                                }}
                                checked={convertBoolean(sportsSetting.tennisYn)}
                                className="group relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent bg-gray-200 transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:ring-offset-2 data-[checked]:bg-indigo-600">
                                    <span
                                        aria-hidden="true"
                                        className="pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out group-data-[checked]:translate-x-5"/>
                            </Switch>
                        </div>
                        <div className={"flex flex-row gap-2 items-center"}>
                            <span className={"w-[80px] text-sm font-bold"}>야구</span>
                            <Switch
                                onChange={value => {
                                    setSportsSetting({
                                        ...sportsSetting,
                                        baseballYn: convertBoolean(value)
                                    })
                                }}
                                checked={convertBoolean(sportsSetting.baseballYn)}
                                className="group relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent bg-gray-200 transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:ring-offset-2 data-[checked]:bg-indigo-600">
                                    <span
                                        aria-hidden="true"
                                        className="pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out group-data-[checked]:translate-x-5"/>
                            </Switch>
                        </div>
                        <div className={"flex flex-row gap-2 items-center"}>
                            <span className={"w-[80px] text-sm font-bold"}>하키</span>
                            <Switch
                                onChange={value => {
                                    setSportsSetting({
                                        ...sportsSetting,
                                        icehockeyYn: convertBoolean(value)
                                    })
                                }}
                                checked={convertBoolean(sportsSetting.icehockeyYn)}
                                className="group relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent bg-gray-200 transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:ring-offset-2 data-[checked]:bg-indigo-600">
                                    <span
                                        aria-hidden="true"
                                        className="pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out group-data-[checked]:translate-x-5"/>
                            </Switch>
                        </div>
                        <div className={"flex flex-row gap-2 items-center"}>
                            <span className={"w-[80px] text-sm font-bold"}>농구</span>
                            <Switch
                                onChange={value => {
                                    setSportsSetting({
                                        ...sportsSetting,
                                        basketballYn: convertBoolean(value)
                                    })
                                }}
                                checked={convertBoolean(sportsSetting.basketballYn)}
                                className="group relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent bg-gray-200 transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:ring-offset-2 data-[checked]:bg-indigo-600">
                                    <span
                                        aria-hidden="true"
                                        className="pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out group-data-[checked]:translate-x-5"/>
                            </Switch>
                        </div>
                        <div className={"flex flex-row gap-2 items-center"}>
                            <span className={"w-[80px] text-sm font-bold"}>핸드볼</span>
                            <Switch
                                onChange={value => {
                                    setSportsSetting({
                                        ...sportsSetting,
                                        handballYn: convertBoolean(value)
                                    })
                                }}
                                checked={convertBoolean(sportsSetting.handballYn)}
                                className="group relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent bg-gray-200 transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:ring-offset-2 data-[checked]:bg-indigo-600">
                                    <span
                                        aria-hidden="true"
                                        className="pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out group-data-[checked]:translate-x-5"/>
                            </Switch>
                        </div>
                        <div className={"flex flex-row gap-2 items-center"}>
                            <span className={"w-[80px] text-sm font-bold"}>배구</span>
                            <Switch
                                onChange={value => {
                                    setSportsSetting({
                                        ...sportsSetting,
                                        volleyballYn: convertBoolean(value)
                                    })
                                }}
                                checked={convertBoolean(sportsSetting.volleyballYn)}
                                className="group relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent bg-gray-200 transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:ring-offset-2 data-[checked]:bg-indigo-600">
                                    <span
                                        aria-hidden="true"
                                        className="pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out group-data-[checked]:translate-x-5"/>
                            </Switch>
                        </div>
                        <div className={"flex flex-row gap-2 items-center"}>
                            <span className={"w-[80px] text-sm font-bold"}>탁구</span>
                            <Switch
                                onChange={value => {
                                    setSportsSetting({
                                        ...sportsSetting,
                                        tabletennisYn: convertBoolean(value)
                                    })
                                }}
                                checked={convertBoolean(sportsSetting.tabletennisYn)}
                                className="group relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent bg-gray-200 transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:ring-offset-2 data-[checked]:bg-indigo-600">
                                    <span
                                        aria-hidden="true"
                                        className="pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out group-data-[checked]:translate-x-5"/>
                            </Switch>
                        </div>
                        <div className={"flex flex-row gap-2 items-center"}>
                            <span className={"w-[80px] text-sm font-bold"}>E스포츠</span>
                            <Switch
                                onChange={value => {
                                    setSportsSetting({
                                        ...sportsSetting,
                                        esportsYn: convertBoolean(value)
                                    })
                                }}
                                checked={convertBoolean(sportsSetting.esportsYn)}
                                className="group relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent bg-gray-200 transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:ring-offset-2 data-[checked]:bg-indigo-600">
                                    <span
                                        aria-hidden="true"
                                        className="pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out group-data-[checked]:translate-x-5"/>
                            </Switch>
                        </div>
                    </div>
                </div>
                <div className={"flex flex-col"}>
                    <div className={"flex flex-row items-center gap-2 justify-between"}>
                        {/*<span className={"p-1 rounded border-2 border-indigo-500 text-sm font-bold"}>게임별 요율설정(카지노)</span>*/}
                        {/*<span className={"p-1 rounded border-2 border-indigo-500 text-sm font-bold"}>게임별 요율설정(슬롯)</span>*/}
                        {/*<span className={"p-1 rounded border-2 border-indigo-500 text-sm font-bold"}>게임별 요율설정(미니게임)</span>*/}
                        <span className={"p-1 rounded-t border-t-2 border-x-2 border-indigo-500 text-sm font-bold"}>게임별 요율설정(스포츠)</span>
                        <button className={"rounded text-xs text-white bg-gray-800 px-2 py-1"} onClick={saveSportsPer}>저장</button>
                    </div>
                    <table className={"border-1"}>
                        <thead className={"bg-gray-100 p-1"}>
                            <tr>
                                <th className={"text-sm"}>일괄적용</th>
                                <th className={"text-sm"}>이름</th>
                                <th className={"text-sm"}>롤링</th>
                                <th className={"text-sm"}>루징</th>
                                <th className={"text-sm"}>이름</th>
                                <th className={"text-sm"}>롤링</th>
                                <th className={"text-sm"}>루징</th>
                                <th className={"text-sm"}>이름</th>
                                <th className={"text-sm"}>롤링</th>
                                <th className={"text-sm"}>루징</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td className={"p-1 text-sm"}>
                                    <div className={"flex flex-row gap-1"}>
                                        <span className={"text-sm"}>롤링</span>
                                        <button className={"px-1 bg-gray-600 text-white"}>최소</button>
                                        <input className={"w-[50px] border-1 px-1"}
                                               type={"number"}
                                               min={0}
                                               step={0.1}
                                               onChange={e => {
                                                   setDefaultRolling(+e.target.value)
                                               }}
                                               value={defaultRolling}/>
                                        <button className={"px-1 bg-gray-600 text-white"}>최대</button>
                                        <button className={"px-1 bg-red-500 text-white"} onClick={() => {
                                            setSportsPer({
                                                ...sportsPer,
                                                soccerRolling: defaultRolling,
                                                footballRolling: defaultRolling,
                                                boxingRolling: defaultRolling,
                                                tennisRolling: defaultRolling,
                                                baseballRolling: defaultRolling,
                                                icehockeyRolling: defaultRolling,
                                                basketballRolling: defaultRolling,
                                                handballRolling: defaultRolling,
                                                volleyballRolling: defaultRolling,
                                                tabletennisRolling: defaultRolling,
                                                esportsRolling: defaultRolling,
                                            })
                                        }}>적용</button>
                                    </div>
                                </td>
                                <td className={"p-1 text-sm"}>축구</td>
                                <td className={"p-1 text-sm"}>
                                    <input className={"w-[50px] border-1 px-1"}
                                           type={"number"}
                                           min={0}
                                           step={0.1}
                                           onChange={e => {
                                               setSportsPer({
                                                   ...sportsPer,
                                                   soccerRolling: +e.target.value
                                               })
                                           }}
                                           value={sportsPer.soccerRolling}/>
                                </td>
                                <td className={"p-1 text-sm"}>
                                    <input className={"w-[50px] border-1 px-1"}
                                           type={"number"}
                                           min={0}
                                           step={0.1}
                                           onChange={e => {
                                               setSportsPer({
                                                   ...sportsPer,
                                                   soccerLosing: +e.target.value
                                               })
                                           }}
                                           value={sportsPer.soccerLosing}/>
                                </td>
                                <td className={"p-1 text-sm"}>미식축구</td>
                                <td className={"p-1 text-sm"}>
                                    <input className={"w-[50px] border-1 px-1"}
                                           type={"number"}
                                           min={0}
                                           step={0.1}
                                           onChange={e => {
                                               setSportsPer({
                                                   ...sportsPer,
                                                   footballRolling: +e.target.value
                                               })
                                           }}
                                           value={sportsPer.footballRolling}/>
                                </td>
                                <td className={"p-1 text-sm"}>
                                    <input className={"w-[50px] border-1 px-1"}
                                           type={"number"}
                                           min={0}
                                           step={0.1}
                                           onChange={e => {
                                               setSportsPer({
                                                   ...sportsPer,
                                                   footballLosing: +e.target.value
                                               })
                                           }}
                                           value={sportsPer.footballLosing}/>
                                </td>
                                <td className={"p-1 text-sm"}>복싱/UFC</td>
                                <td className={"p-1 text-sm"}>
                                    <input className={"w-[50px] border-1 px-1"}
                                           type={"number"}
                                           min={0}
                                           step={0.1}
                                           onChange={e => {
                                               setSportsPer({
                                                   ...sportsPer,
                                                   boxingRolling: +e.target.value
                                               })
                                           }}
                                           value={sportsPer.boxingRolling}/>
                                </td>
                                <td className={"p-1 text-sm"}>
                                    <input className={"w-[50px] border-1 px-1"}
                                           type={"number"}
                                           min={0}
                                           step={0.1}
                                           onChange={e => {
                                               setSportsPer({
                                                   ...sportsPer,
                                                   boxingLosing: +e.target.value
                                               })
                                           }}
                                           value={sportsPer.boxingLosing}/>
                                </td>
                            </tr>
                            <tr>
                                <td className={"p-1 text-sm"}>
                                    <div className={"flex flex-row gap-1"}>
                                        <span className={"text-sm"}>루징</span>
                                        <button className={"px-1 bg-gray-600 text-white"}>최소</button>
                                        <input className={"w-[50px] border-1 px-1"}
                                               type={"number"}
                                               min={0}
                                               step={0.1}
                                               onChange={e => {
                                                   setDefaultLosing(+e.target.value)
                                               }}
                                               value={defaultLosing}/>
                                        <button className={"px-1 bg-gray-600 text-white"}>최대</button>
                                        <button className={"px-1 bg-red-500 text-white"} onClick={() => {
                                            setSportsPer({
                                                ...sportsPer,
                                                soccerLosing: defaultLosing,
                                                footballLosing: defaultLosing,
                                                boxingLosing: defaultLosing,
                                                tennisLosing: defaultLosing,
                                                baseballLosing: defaultLosing,
                                                icehockeyLosing: defaultLosing,
                                                basketballLosing: defaultLosing,
                                                handballLosing: defaultLosing,
                                                volleyballLosing: defaultLosing,
                                                tabletennisLosing: defaultLosing,
                                                esportsLosing: defaultLosing,
                                            })
                                        }}>적용</button>
                                    </div>
                                </td>
                                <td className={"p-1 text-sm"}>테니스</td>
                                <td className={"p-1 text-sm"}>
                                    <input className={"w-[50px] border-1 px-1"}
                                           type={"number"}
                                           min={0}
                                           step={0.1}
                                           onChange={e => {
                                               setSportsPer({
                                                   ...sportsPer,
                                                   tennisRolling: +e.target.value
                                               })
                                           }}
                                           value={sportsPer.tennisRolling}/>
                                </td>
                                <td className={"p-1 text-sm"}>
                                    <input className={"w-[50px] border-1 px-1"}
                                           type={"number"}
                                           min={0}
                                           step={0.1}
                                           onChange={e => {
                                               setSportsPer({
                                                   ...sportsPer,
                                                   tennisLosing: +e.target.value
                                               })
                                           }}
                                           value={sportsPer.tennisLosing}/>
                                </td>
                                <td className={"p-1 text-sm"}>야구</td>
                                <td className={"p-1 text-sm"}>
                                    <input className={"w-[50px] border-1 px-1"}
                                           type={"number"}
                                           min={0}
                                           step={0.1}
                                           onChange={e => {
                                               setSportsPer({
                                                   ...sportsPer,
                                                   baseballRolling: +e.target.value
                                               })
                                           }}
                                           value={sportsPer.baseballRolling}/>
                                </td>
                                <td className={"p-1 text-sm"}>
                                    <input className={"w-[50px] border-1 px-1"}
                                           type={"number"}
                                           min={0}
                                           step={0.1}
                                           onChange={e => {
                                               setSportsPer({
                                                   ...sportsPer,
                                                   baseballLosing: +e.target.value
                                               })
                                           }}
                                           value={sportsPer.baseballLosing}/>
                                </td>
                                <td className={"p-1 text-sm"}>아이스하키</td>
                                <td className={"p-1 text-sm"}>
                                    <input className={"w-[50px] border-1 px-1"}
                                           type={"number"}
                                           min={0}
                                           step={0.1}
                                           onChange={e => {
                                               setSportsPer({
                                                   ...sportsPer,
                                                   icehockeyRolling: +e.target.value
                                               })
                                           }}
                                           value={sportsPer.icehockeyRolling}/>
                                </td>
                                <td className={"p-1 text-sm"}>
                                    <input className={"w-[50px] border-1 px-1"}
                                           type={"number"}
                                           min={0}
                                           step={0.1}
                                           onChange={e => {
                                               setSportsPer({
                                                   ...sportsPer,
                                                   icehockeyLosing: +e.target.value
                                               })
                                           }}
                                           value={sportsPer.icehockeyLosing}/>
                                </td>
                            </tr>
                            <tr>
                                <td className={"p-1 text-sm"} />
                                <td className={"p-1 text-sm"}>농구</td>
                                <td className={"p-1 text-sm"}>
                                    <input className={"w-[50px] border-1 px-1"}
                                           type={"number"}
                                           min={0}
                                           step={0.1}
                                           onChange={e => {
                                               setSportsPer({
                                                   ...sportsPer,
                                                   basketballRolling: +e.target.value
                                               })
                                           }}
                                           value={sportsPer.basketballRolling}/>
                                </td>
                                <td className={"p-1 text-sm"}>
                                    <input className={"w-[50px] border-1 px-1"}
                                           type={"number"}
                                           min={0}
                                           step={0.1}
                                           onChange={e => {
                                               setSportsPer({
                                                   ...sportsPer,
                                                   basketballLosing: +e.target.value
                                               })
                                           }}
                                           value={sportsPer.basketballLosing}/>
                                </td>
                                <td className={"p-1 text-sm"}>핸드볼</td>
                                <td className={"p-1 text-sm"}>
                                    <input className={"w-[50px] border-1 px-1"}
                                           type={"number"}
                                           min={0}
                                           step={0.1}
                                           onChange={e => {
                                               setSportsPer({
                                                   ...sportsPer,
                                                   handballRolling: +e.target.value
                                               })
                                           }}
                                           value={sportsPer.handballRolling}/>
                                </td>
                                <td className={"p-1 text-sm"}>
                                    <input className={"w-[50px] border-1 px-1"}
                                           type={"number"}
                                           min={0}
                                           step={0.1}
                                           onChange={e => {
                                               setSportsPer({
                                                   ...sportsPer,
                                                   handballLosing: +e.target.value
                                               })
                                           }}
                                           value={sportsPer.handballLosing}/>
                                </td>
                                <td className={"p-1 text-sm"}>배구</td>
                                <td className={"p-1 text-sm"}>
                                    <input className={"w-[50px] border-1 px-1"}
                                           type={"number"}
                                           min={0}
                                           step={0.1}
                                           onChange={e => {
                                               setSportsPer({
                                                   ...sportsPer,
                                                   volleyballRolling: +e.target.value
                                               })
                                           }}
                                           value={sportsPer.volleyballRolling}/>
                                </td>
                                <td className={"p-1 text-sm"}>
                                    <input className={"w-[50px] border-1 px-1"}
                                           type={"number"}
                                           min={0}
                                           step={0.1}
                                           onChange={e => {
                                               setSportsPer({
                                                   ...sportsPer,
                                                   volleyballLosing: +e.target.value
                                               })
                                           }}
                                           value={sportsPer.volleyballLosing}/>
                                </td>
                            </tr>
                            <tr>
                                <td className={"p-1 text-sm"} />
                                <td className={"p-1 text-sm"}>탁구</td>
                                <td className={"p-1 text-sm"}>
                                    <input className={"w-[50px] border-1 px-1"}
                                           type={"number"}
                                           min={0}
                                           step={0.1}
                                           onChange={e => {
                                               setSportsPer({
                                                   ...sportsPer,
                                                   tabletennisRolling: +e.target.value
                                               })
                                           }}
                                           value={sportsPer.tabletennisRolling}/>
                                </td>
                                <td className={"p-1 text-sm"}>
                                    <input className={"w-[50px] border-1 px-1"}
                                           type={"number"}
                                           min={0}
                                           step={0.1}
                                           onChange={e => {
                                               setSportsPer({
                                                   ...sportsPer,
                                                   tabletennisLosing: +e.target.value
                                               })
                                           }}
                                           value={sportsPer.tabletennisLosing}/>
                                </td>
                                <td className={"p-1 text-sm"}>E스포츠</td>
                                <td className={"p-1 text-sm"}>
                                    <input className={"w-[50px] border-1 px-1"}
                                           type={"number"}
                                           min={0}
                                           step={0.1}
                                           onChange={e => {
                                               setSportsPer({
                                                   ...sportsPer,
                                                   esportsRolling: +e.target.value
                                               })
                                           }}
                                           value={sportsPer.esportsRolling}/>
                                </td>
                                <td className={"p-1 text-sm"}>
                                    <input className={"w-[50px] border-1 px-1"}
                                           type={"number"}
                                           min={0}
                                           step={0.1}
                                           onChange={e => {
                                               setSportsPer({
                                                   ...sportsPer,
                                                   esportsLosing: +e.target.value
                                               })
                                           }}
                                           value={sportsPer.esportsLosing}/>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                        {/*{sportsList.map((item, idx) => {*/}
                        {/*    return (*/}
                        {/*        <div key={String(idx)} className={"flex flex-row gap-2"}>*/}
                        {/*            <span className={"text-sm"}>{item.sport}</span>*/}
                        {/*            <Switch*/}
                        {/*                checked={item.useYn}*/}
                        {/*                className="group relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent bg-gray-200 transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:ring-offset-2 data-[checked]:bg-indigo-600">*/}
                        {/*            <span*/}
                        {/*                aria-hidden="true"*/}
                        {/*                className="pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out group-data-[checked]:translate-x-5"/>*/}
                        {/*            </Switch>*/}
                        {/*        </div>*/}
                        {/*    )*/}
                        {/*})}*/}
                </div>
            </div>

            <CModal backdrop={"static"}
                    alignment={"center"}
                    visible={pointModal.visible}
                    onClose={() => {
                        setPointModal({visible: false, type: "0"})}}>
                <UserPointModal user={userInfo}
                                type={pointModal.type}
                                proc={(point) => {
                                    procPoint(point)
                                }}
                                close={() => {
                                    setPointModal({visible: false, type: "0"})
                                }}
                />
            </CModal>

            <CModal backdrop={"static"}
                    alignment={"center"}
                    visible={moneyModal.visible}
                    onClose={() => {
                        setMoneyModal({visible: false, type: "0"})}}>
                <UserMoneyModal user={userInfo}
                                type={moneyModal.type}
                                proc={(money) => {
                                    procMoney(money)
                                }}
                                close={() => {
                                    setMoneyModal({visible: false, type: "0"})
                                }}
                />
            </CModal>
        </div>
    )
}

const mapStateToProps = (state) => {
    const {account} = state;
    return {
        account
    }
}

export default connect(mapStateToProps, null)(UserDetail);