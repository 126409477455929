import React from 'react'
import {useLocation, useNavigate} from 'react-router-dom'
import PropTypes from 'prop-types'

import {CBadge, CNavGroup} from '@coreui/react'
import {connect} from "react-redux";
import {footerActions} from "../../../redux/actions/footer";

const AppSidebarNav = (props) => {
    const navigate = useNavigate();
    const location = useLocation()
    const navLink = (name, icon, badge) => {
        return (
            <>
                {icon && icon}
                {name && name}
                {badge && (
                    <CBadge color={badge.color} className="ms-auto">
                        {badge.text}
                    </CBadge>
                )}
            </>
        )
    }

    const navItem = (item, index) => {
        const {component, name, badge, icon, ...rest} = item
        const Component = component
        return (
            <Component
                {...(rest.to && {
                    onClick: () => onClick(item)
                })}
                key={index}
                {...rest}
            >
                {navLink(name, icon, badge)}
            </Component>
        )
    }

    const navGroup = (item, index) => {
        const {component, name, icon, to, ...rest} = item
        const Component = component
        return (
            <Component
                idx={String(index)}
                key={index}
                toggler={navLink(name, icon)}
                visible={location.pathname.startsWith(to)}
                {...rest}
            >
                {item.items?.map((item, index) =>
                    item.component === CNavGroup ? navGroup(item, index) : navItem(item, index)
                )}
            </Component>
        )
    }

    const onClick = (menu) => {
        navigate(menu.to);
        props.setSelectedMenu(menu);
        props.addFooterMenu(menu);
    }

    return (
        <React.Fragment>
            {props.items &&
            props.items.map((item, index) => (item.component === CNavGroup ? navGroup(item, index) : navItem(item, index)))}
        </React.Fragment>
    )
}

AppSidebarNav.propTypes = {
    items: PropTypes.arrayOf(PropTypes.any).isRequired,
}

const mapStateToProps = (state) => {
    const {account, store, footer} = state;
    return {
        account, store, footer
    };
}

const mapDispatchToProps = (dispatch) => {
    return {
        addFooterMenu: (menu) => {
            dispatch(footerActions.addFooterMenu(menu));
        }
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(AppSidebarNav);
