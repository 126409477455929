import React, {Suspense} from "react";
import {Provider} from "react-redux";
import {BrowserRouter, HashRouter, Route, Routes} from "react-router-dom";
import store from "./redux/store";

import './App.css';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import "./assets/scss/style.scss";

import Login from "./pages/domain/login/Login";
import DefaultLayout from "./pages/domain/main/DefaultLayout";
import UserDetail from "./pages/domain/user/UserDetail";

const loading = (
    <div className={"pt-3 text-center"}>
        <div className={"sk-spinner sk-spinner-pulse"}/>
    </div>
);

function App() {
    return (
        <Provider store={store}>
            <BrowserRouter>
                <Suspense fallback={loading}>
                    <Routes>
                        <Route path={"/"} name={"Login"} element={<Login/>}/>
                        <Route exact path="/login" name="Login Page" element={<Login/>}/>
                        <Route exact path="/main" name="Dashboard" element={<DefaultLayout/>}/>
                        <Route exact path="/dashboard" name="Dashboard" element={<DefaultLayout/>}/>
                        <Route exact path={"/user/detail"} name={"UserDetail"} element={<UserDetail />} />
                        <Route exact path="/*" name="Dashboard" element={<DefaultLayout/>}/>
                        <Route exact path="/**/*" name="Dashboard" element={<DefaultLayout/>}/>
                    </Routes>
                </Suspense>
            </BrowserRouter>
        </Provider>
    )
}

export default App;
