import React, {useState} from 'react'
import {CButton, CFooter, CForm, CNavItem, CNavLink} from '@coreui/react'
import {connect} from "react-redux";

const AppFooter = (props) => {
	const { menuList } = props.footer;
	const activeKey = props.activeKey;

	return (
		<div className={"row row-cols-lg-auto g-1 align-items-center m-0 p-1 bg-gray-100"}>
			{ menuList.map((item, idx) => {
				return (
					<CNavItem key={String(idx)}>
						<CNavLink active={activeKey === item.id} className={"px-3 py-1"}
								  onClick={() => props.setSelectedMenu(item)}
								  onDoubleClick={() => props.onDeleteMenu(item)}>
							<span className={"text-xs"}>{item.name}</span>
						</CNavLink>
					</CNavItem>
				)
			})}
		</div>
	)
}

const mapStateToProps = (state) => {
	const {footer} = state;
	return {
		footer
	};
}

export default connect(mapStateToProps, null)(AppFooter);
