import {connect} from "react-redux";
import {useEffect, useState} from "react";
import {accountActions} from "../../../redux/actions/account";
import {footerActions} from "../../../redux/actions/footer";
import {useLocation, useNavigate} from "react-router-dom";
import sha256 from "sha256";
import qs from 'query-string';
import * as api from "../../../api/api";
import Loading from "../../common/Loading";
import {CNavItem} from "@coreui/react";

const Login = (props) => {
	const [userId, setUserId] = useState("");
	const [password, setPassword] = useState("");
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState({ visible: false, message: "" });

	const navigate = useNavigate();
	const {search} = useLocation();

	useEffect(() => {
		props.initFooterMenu();

		if (search.length > 0) {
			const query = qs.parse(search);
			const login = {
				id: query.id,
				password: query.pass
			}
			acceptLogin(login);
		}
	}, [])

	const validation = () => {
		if (userId.length === 0) {
			return;
		}
		if (password.length === 0) {
			return;
		}
		const login = {
			id: userId,
			// password: sha256(password)
			password: password
		}
		acceptLogin(login);
	}

	const acceptLogin = (login) => {
		setError({ visible: false, message: "" });
		setLoading(true);
		const params = {
			userId: login.id,
			password: login.password
		}
		api.login(params).then(result => {
			const {data, status, statusText} = result;
			if (status === 200) {
				console.log("### data :: ", data)
				if (data.status === 1) {
					props.setAccount(data);
					goMain()
				}
				else {
					window.alert("로그인 불가")
				}
			}
			else {
				window.alert(statusText);
			}
		})
			.catch(ex => {
				window.alert("서버에 문제가 있습니다.\n관리자에게 문의해주세요.\n(" + ex.message + ")");
			})
			.finally(() => {
				setLoading(false)
			})
	}

	const goMain = () => {
		const menu = {
			id: 0,
			component: CNavItem,
			name: '대시보드',
			to: '/dashboard',
		}
		props.addFooterMenu(menu);
		navigate("/dashboard");
	}

	return (
		<div className="bg-body-tertiary min-vh-100 d-flex flex-row align-items-center">
			{loading ? <Loading /> : null}
			<div className="container">
				<div className="row justify-content-center">
					<div className="col-lg-8">
						<div className="card-group d-block d-md-flex row">
							<div className="card col-md-5 text-white bg-primary py-5">
								<div className="card-body text-center">
									<div>
										<h2>Gambling 프로젝트</h2>
										<div className="btn btn-lg btn-outline-light mt-3">
											LOG-IN
										</div>
									</div>
								</div>
							</div>
							<div className="card col-md-7 p-4 mb-0">
								<div className="card-body">
									<div className="input-group mb-3">
										<input className="form-control"
											   type="text" placeholder="ID"
											   onChange={(event) => {setUserId(event.target.value);}}  />
									</div>
									<div className="input-group mb-4">
										<input className="form-control"
											   type="password"
											   placeholder="Password"
											   onChange={(event) => {setPassword(event.target.value);}}
											   onKeyPress={(e) => {
											   	if (e.key === "Enter") {
											   		validation()
											   	}
											   }}/>
									</div>
									<div className="row">
										<div className="col-6">
											<button className="btn btn-primary px-4"
													type="button"
													onClick={() => validation()}>로그인</button>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}

const mapDispatchToProps = (dispatch) => {
	return {
		setAccount: (account) => {
			dispatch(accountActions.setAccount(account));
		},
		initFooterMenu: () => {
			dispatch(footerActions.initFooterMenu());
		},
		addFooterMenu: (menu) => {
			dispatch(footerActions.addFooterMenu(menu));
		},
		dispatch
	}
}

const mapStateToProps = (state) => {
	const {account, footer} = state;
	return {
		account, footer
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Login)
