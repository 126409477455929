import axios from "axios";

// export const host = process.env.REACT_APP_API_URL;
// export const host = "http://localhost:18080"
export const host = "http://208.109.245.156:18080"
export const host2 = host;

// const proxy = "";
const proxy = "https://ziha-proxy-bef22eb1e8af.herokuapp.com/";

export function post(request, body) {
    return axios.post(proxy + request, body);
}

export function postQueryString(request, config) {
    return axios.post(proxy + request, null, config);
}

export function get(request, body) {
    return axios.get(proxy + request, {
        params: body
    });
}

export function put(request, body) {
    return axios.put(proxy + request, body);
}

export function patch(request, body) {
    return axios.patch(proxy + request, body);
}

export function deleteM(request, body) {
    return axios.delete(request, {data: body});
}

export function login(params) {
    const request = host + "/api/v1/login/";
    return post(request, params);
}

export function getGUserPartnerList() {
    const request = host2 + "/api/v1/partner/list";
    return get(request);
}

export function getGUser(userId) {
    const request = host2 + "/api/v1/user/" + userId;
    return get(request);
}

export function getGUserList(params) {
    const request = host2 + "/api/v1/user/list";
    return post(request, params);
}

export function getGUserListByUserType(userType) {
    const request = host2 + "/api/v1/user/userType/" + userType;
    return get(request);
}

export function getGUserListByAccept() {
    const request = host2 + "/api/v1/user/accept/list";
    return get(request);
}

export function putGUser(gUser) {
    const request = host2 + "/api/v1/user/";
    return put(request, gUser);
}

export function putDeposit(deposit) {
    const request = host2 + "/api/v1/user/cash/deposit";
    return put(request, deposit)
}

export function getRequestDepositList(params) {
    const request = host2 + "/api/v1/user/cash/deposit/request/list";
    return post(request, params);
}

export function getDepositList(params) {
    const request = host2 + "/api/v1/user/cash/deposit/list";
    return post(request, params);
}

export function putWithdraw(withdraw) {
    const request = host2 + "/api/v1/user/cash/withdraw"
    return put(request, withdraw)
}

export function getRequestWithdrawList(params) {
    const request = host2 + "/api/v1/user/cash/withdraw/request/list"
    return post(request, params)
}

export function getWithdrawList(params) {
    const request = host2 + "/api/v1/user/cash/withdraw/list"
    return post(request, params)
}

export function getDashboard() {
    const request = host2 + "/api/v1/game/dashboard/today"
    return get(request)
}

// 게시판
export function getBoardList(boardId) {
    const request = host2 + "/api/v1/board/" + boardId
    return get(request)
}

export function postBoard(notice) {
    const request = host2 + "/api/v1/board/post"
    return put(request, notice)
}

// 설정
export function getSiteConfig(siteId) {
    const request = host2 + "/api/v1/config/site/" + siteId
    return get(request)
}

export function putSiteConfig(config) {
    const request = host2 + "/api/v1/config/site"
    return put(request, config)
}

export function getSiteConfigUserLevel(siteId, userLevel) {
    const request = host2 + "/api/v1/config/site/" + siteId + "/" + userLevel
    return get(request)
}

export function putSiteConfigUserLevel(siteLevelConfig) {
    const request = host2 + "/api/v1/config/site/level"
    return put(request, siteLevelConfig)
}

// 점검 설정
export function getSiteInspection(siteId) {
    const request = host2 + "/api/v1/config/site/inspection/" + siteId
    return get(request)
}

export function putSiteInspection(inspect) {
    const request = host2 + "/api/v1/config/site/inspection"
    return put(request, inspect)
}

// 스포츠 설정 조회
export function getSportsConfig(params) {
    const request = host2 + "/api/v1/sports/config/list"
    return post(request, params)
}

// 경기 종목 조회
export function getSportsBaseList() {
    const request = host2 + "/api/v1/sports/base/list";
    return get(request)
}

// 사용자별 경기 설정
export function putSportsSetting(setting) {
    const request = host2 + "/api/v1/user/sports/setting"
    return put(request, setting)
}

// 사용자별 요율 설정
export function putSportsPer(sportsPer) {
    const request = host2 + "/api/v1/user/sports/per"
    return put(request, sportsPer);
}

// 보유금 처리
export function postMoney(money) {
    const request = host2 + "/api/v1/cash/post"
    return post(request, money)
}

export function postPoint(point) {
    const request = host2 + "/api/v1/point/post"
    return post(request, point)
}

// 로그인 이력
export function loginHistory(params) {
    const request = host2 + "/api/v1/login/history"
    return post(request, params)
}

export function getBettingList(params) {
    const request = host2 + "/api/v1/betting/list"
    return post(request, params)
}