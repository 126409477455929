import {connect} from "react-redux";
import {useState} from "react";
import dayjs from "dayjs";
import Loading from "../../common/Loading";
import InputRangeDate from "../../common/InputRangeDate";
import {AgGridReact} from "ag-grid-react";

/* 기간별 정산 내역 */
const SettlePeriod = (props) => {
    const [loading, setLoading] = useState(false)
    const [searchForm, setSearchForm] = useState({
        startTime: dayjs().add(-30, "d"),
        endTime: dayjs()
    })
    const [settleData, setSettleData] = useState([])
    const [data, setData] = useState([])

    const defaultColDef = {
        flex: 1,
        resizable: true,
        sortable: true,
        filter: false,
        autoHeaderHeight: true
    }

    const handleWhere = (e) => {
        const {name, value} = e.target;
        setSearchForm({
            ...searchForm,
            [name]: value
        })
    }

    const handleDate = (date) => {
        setSearchForm({
            ...searchForm,
            startTime: date[0].format("YYYYMMDD000000"),
            endTime: date[1].format("YYYYMMDD235959")
        })
    }

    const onSearch = () => {

    }

    const [settleColumnDefs] = useState([
        {field: 'seq', headerName: 'No', width: 40, hide: true},
        {field: 'userId', headerName: '아이디'},
        {field: 'nickname', headerName: "닉네임"},
        {field: 'grade', headerName: "등급"},
        {field: 'deptCd', headerName: "소속코드", hide: true},
        {field: 'deptNm', headerName: "소속"},
        {field: 'mobileNo', headerName: "전화번호"},
        {field: 'account', headerName: "환불계좌"},
        {field: 'upUserId', headerName: "상위아이디"},
        {field: 'instTime', headerName: '가입일'},
        {field: 'joinSite', headerName: '가입사이트'},
        {field: 'joinIp', headerName: '가입IP'},
        {field: 'status', headerName: "상태변경"},
        {field: 'rejectTxt', headerName: '거절사유'},
    ])

    const [columnDefs] = useState([
        {field: 'No', headerName: 'No', maxWidth: 40, valueGetter: 'node.rowIndex + 1'},
        {field: 'seq', headerName: 'No', width: 40, hide: true},
        {field: 'userId', headerName: '이름'},
        {field: 'grade', headerName: "적용요율(%)"},
        {field: 'mobileNo', headerName: "보유금액"},
        {field: 'account', headerName: "입출금"},
        {field: 'upUserId', headerName: "스포츠"},
        {field: 'instTime', headerName: '총배팅'}
    ])

    return (
        <div className={"flex flex-col"}>
            {loading ? <Loading /> : null}
            <p className={"text-md font-bold"}>정산 관리 > 일자별 정산 내역</p>
            <div className={"bg-gray-100 border"}>
                <div className={"flex flex-row p-2 gap-1"}>
                    <InputRangeDate title={"날짜"} startTime={searchForm.startTime} endTime={searchForm.endTime} setValue={handleDate} />
                    <button className={"bg-o2-blue px-3 rounded text-white text-sm"} onClick={onSearch}>조회</button>
                </div>
            </div>
            <h6 className={"text-md font-bold mt-4"}>합계</h6>
            <div className="ag-theme-alpine mt-1" style={{width: '100%', height: 300}}>
                <AgGridReact
                    rowSelection={'single'}
                    rowHeight={32}
                    headerHeight={32}
                    rowData={data}
                    pagination={true}
                    defaultColDef={defaultColDef}
                    columnDefs={columnDefs}>
                </AgGridReact>
            </div>
            <div className="ag-theme-alpine mt-1" style={{width: '100%', height: 600}}>
                <AgGridReact
                    rowSelection={'single'}
                    rowHeight={32}
                    headerHeight={32}
                    rowData={data}
                    pagination={true}
                    defaultColDef={defaultColDef}
                    columnDefs={columnDefs}>
                </AgGridReact>
            </div>
        </div>
    )
}

const mapStateToProps = (state) => {
    const {account, store} = state;
    return {
        account, store
    }
}

export default connect(mapStateToProps, null)(SettlePeriod);