import React from 'react'
import { useSelector, useDispatch } from 'react-redux'

import { CSidebar, CSidebarBrand, CSidebarNav, CRow } from '@coreui/react'

import AppSidebarNav from './AppSidebarNav'

import SimpleBar from 'simplebar-react'
import 'simplebar-react/dist/simplebar.min.css'

import Navigation from "../../../_nav";

const AppSidebar = (props) => {
	const dispatch = useDispatch()
	const unfoldable = useSelector((props) => props.state.sidebarUnfoldable)
	const sidebarShow = useSelector((props) => props.state.sidebarShow)
	const {user} = useSelector((props) => props.account);

	const menu = Navigation();

	return (
		<CSidebar
			position="fixed"
			unfoldable={unfoldable}
			visible={sidebarShow}
			onVisibleChange={(visible) => {
				dispatch({ type: 'set', sidebarShow: visible })
			}}
		>
			<CSidebarBrand className="p-3 d-none d-md-flex" to="/">
				<center>
					<CRow>
						<h4 className="text-white">{user ? user.nickName : ""} 님</h4>
						<h6 className="text-danger">online</h6>
					</CRow>
				</center>
			</CSidebarBrand>
			<CSidebarNav>
				<SimpleBar>
					<AppSidebarNav items={menu} setSelectedMenu={props.setSelectedMenu} />
				</SimpleBar>
			</CSidebarNav>
		</CSidebar>
	)
}

export default React.memo(AppSidebar)
