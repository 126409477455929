import React, {useEffect, useState} from 'react'
import {useSelector, useDispatch} from 'react-redux'
import {
    CContainer,
    CHeader,
    CHeaderBrand,
    CHeaderNav,
    CHeaderToggler,
    CNavLink,
    CNavItem,
} from '@coreui/react'
import CIcon from '@coreui/icons-react'
import {
    cilMenu,
    cilAccountLogout,
} from '@coreui/icons';

const AppHeader = (props) => {
    const proxy = "https://ziha-proxy-bef22eb1e8af.herokuapp.com/";

    const dispatch = useDispatch()
    const sidebarShow = useSelector((state) => state.state.sidebarShow)
    const [depositCount, setDepositCount] = useState(0)
    const [withdrawCount, setWithdrawCount] = useState(0)
    const [userRegisterCount, setUserRegisterCount] = useState(0)

    useEffect(() => {
        const es = new EventSource(proxy + "http://208.109.245.156:18080/api/v1/noti/sse?clientId=abcd12345&topic=notification")
        es.addEventListener('open', event => {
            console.log("### open :: ", event)
        })

        es.addEventListener('receive', event => {
            if (event.data === 'deposit') {
                setDepositCount(prevNumber => prevNumber + 1)
            }
            else if (event.data === 'withdraw') {
                setWithdrawCount(prevNumber => prevNumber + 1)
            }
            else if (event.data === 'register') {
                setUserRegisterCount(prevNumber => prevNumber + 1)
            }
        })

        es.addEventListener('error', error => {
            console.error(error)
        })
    }, [])

    const onClick = (type) => {
        let menu = {}
        switch (type) {
            case "deposit":
                menu = {
                    id: 41,
                    component: CNavItem,
                    name: '입금 신청 내역',
                    to: '/cash/in-request',
                }
                break;
            case "withdraw":
                menu = {
                    id: 43,
                    component: CNavItem,
                    name: '출금 신청 내역',
                    to: '/cash/withdraw-request',
                }
                break;
            case "register":
                menu = {
                    id: 24,
                    component: CNavItem,
                    name: '가입승인 대기',
                    to: '/user/accept',
                }
                break;
        }
        props.move(menu)
    }

    return (
        <CHeader position="sticky" className="mb-2">
            <CContainer fluid>
                <CHeaderToggler
                    className="ps-1"
                    onClick={() => {
                        dispatch({type: 'set', sidebarShow: !sidebarShow})
                    }}
                >
                    <CIcon icon={cilMenu} size="xl"/>
                </CHeaderToggler>
                <CHeaderNav className="d-none d-md-flex me-auto gap-2">
                    <div className={"flex flex-row px-2 py-1 rounded bg-gray-500 items-center gap-2"} onClick={() => onClick("deposit")}>
                        <span className={"text-white text-sm font-bold"}>입금신청</span>
                        <span className={"text-white text-lg font-bold"}>{depositCount}</span>
                    </div>
                    <div className={"flex flex-row px-2 py-1 rounded bg-gray-500 items-center gap-2"} onClick={() => onClick("withdraw")}>
                        <span className={"text-white text-sm font-bold"}>출금신청</span>
                        <span className={"text-white text-lg font-bold"}>{withdrawCount}</span>
                    </div>
                    <div className={"flex flex-row px-2 py-1 rounded bg-gray-500 items-center gap-2"} onClick={() => onClick('register')}>
                        <span className={"text-white text-sm font-bold"}>가입신청</span>
                        <span className={"text-white text-lg font-bold"}>{userRegisterCount}</span>
                    </div>
                </CHeaderNav>
                <CHeaderNav>
                    <CNavItem>
                        <CNavLink href="#/login">
                            <CIcon icon={cilAccountLogout} size="xl"/>
                        </CNavLink>
                    </CNavItem>
                </CHeaderNav>
            </CContainer>
        </CHeader>
    )
}

export default AppHeader
