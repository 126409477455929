import {createStore, combineReducers} from 'redux'
import accountReducer from "../redux/actions/account"
import footerReducer from "../redux/actions/footer"

const initialState = {
	sidebarShow: true
}

const changeState = (state = initialState, {type, ...rest}) => {
	switch (type) {
		case 'set':
			return {...state, ...rest}
		default:
			return state
	}
}

const rootReducer = combineReducers({
	state: changeState,
	account: accountReducer,
	footer: footerReducer
})

const store = createStore(rootReducer)
export default store
