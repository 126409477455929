import React from 'react'

const Loading = (props) => {

    return (
        <div className={"flex flex-col gap-3"} style={{
          position: 'absolute',
          background: '#00000099',
          zIndex: 999,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          top: 0,
          left: 0,
          width: '100vw',
          height: '100vh'
        }} />
    )
}

export default Loading
