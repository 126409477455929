import React from 'react'
import {CNavItem, CTabContent, CTabPane} from '@coreui/react'
import Components from "./AppComponents.js";
import {connect} from "react-redux";

const AppContent = (props) => {
	const {menuList} = props.footer;

	return (
		<div className={"mb-2"}>
			<CTabContent>
				{menuList.map((item, idx) => {
					return (
						<CTabPane key={idx.toString()} role="tabpanel" aria-labelledby="basic-tab" visible={props.activeKey === item.id}>
							<div style={{height: 10}}/>
							{Components(item, props)}
						</CTabPane>
					)
				})}
			</CTabContent>
		</div>
	)
}

const mapStateToProps = (state) => {
	const {footer} = state;
	return {
		footer
	};
}

export default connect(mapStateToProps, null)(AppContent);
