import ic_close from "../../../assets/ic-close.svg";
import {useEffect, useState} from "react";

const UserMoneyModal = (props) => {
    const [title, setTitle] = useState("")
    const [money, setMoney] = useState(0)

    useEffect(() => {
        setTitle(props.type === "0" ? "보유금 지급" : "보유금 회수")
    }, [])

    const post = () => {
        if (window.confirm("보유금을 지급/회수 하시겠어요?")) {
            const moneyBody = {
                procType: props.type,
                userId: props.user.userId,
                type: props.type === "0" ? "AMG" : "AMR",
                cash: money
            }
            props.proc(moneyBody)
            props.close()
        }
    }

    return (
        <div className={"bg-gray-800 py-[30px] rounded"}>
            <div className={"flex flex-row justify-end px-[36px]"} onClick={props.close}>
                <img src={ic_close} alt={""}/>
            </div>
            <p className={"text-white text-center text-4xl font-bold"}>{title}</p>
            <div className={"flex flex-col px-8 mt-8 gap-2"}>
                <div className={"flex flex-row text-white text-sm items-center gap-2"}>
                    <input
                        id="amount"
                        name="amount"
                        type={"number"}
                        value={money}
                        onChange={event => {
                            setMoney(+event.target.value)
                        }}
                        required
                        className="rounded border-0 px-4 py-1.5 text-end text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    />
                </div>
                <button className={"rounded mt-4 p-2 bg-blue-500 text-lg text-white font-bold"}
                        onClick={post}>
                    {title}
                </button>
            </div>
        </div>
    )
}

export default UserMoneyModal