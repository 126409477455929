import React from 'react'
import {CNavGroup, CNavItem} from '@coreui/react'

const Navigation = (props) => {
    return [
        {
            id: 0,
            component: CNavItem,
            name: '대시보드',
            to: '/dashboard',
        },
        {
            id: 10,
            component: CNavGroup,
            name: '마스터 설정',
            to: '/master',
            items: [
                // {
                //     id: 11,
                //     component: CNavItem,
                //     name: '분양 본사 관리',
                //     to: '/master/sale/management'
                // }
            ]
        },
        // {
        //     id: 10,
        //     component: CNavGroup,
        //     name: '파트너 관리',
        //     to: '/partner',
        //     items: [
        //         {
        //             id: 11,
        //             component: CNavItem,
        //             name: '파트너 리스트',
        //             to: '/partner/list',
        //         },
        //         {
        //             id: 12,
        //             component: CNavItem,
        //             name: '파트너 리스트2',
        //             to: '/partner/list2',
        //         },
        //         {
        //             id: 13,
        //             component: CNavItem,
        //             name: '파트너 생성',
        //             to: '/partner/create',
        //         },
        //     ],
        // },
        {
            id: 20,
            component: CNavGroup,
            name: '사용자 관리',
            to: '/user',
            items: [
                // {
                //     id: 21,
                //     component: CNavItem,
                //     name: '회원 생성',
                //     to: '/user/create',
                // },
                {
                    id: 22,
                    component: CNavItem,
                    name: '회원 리스트',
                    to: '/user/list',
                },
                {
                    id: 23,
                    component: CNavItem,
                    name: '로그인 내역',
                    to: '/user/login/history',
                },
                // {
                //     id: 23,
                //     component: CNavItem,
                //     name: '회원 리스트(파트너별)',
                //     to: '/user/list-partner',
                // },
                {
                    id: 24,
                    component: CNavItem,
                    name: '가입승인 대기',
                    to: '/user/accept',
                },
                // {
                //     id: 25,
                //     component: CNavItem,
                //     name: '캐시 이동내역',
                //     to: '/user/cash-history',
                // },
                {
                    id: 26,
                    component: CNavItem,
                    name: '포인트 관리',
                    to: '/user/point',
                },
                // {
                //     id: 27,
                //     component: CNavItem,
                //     name: '사용자 추적',
                //     to: '/user/history',
                // },
                // {
                //     id: 28,
                //     component: CNavItem,
                //     name: '접속중인 회원',
                //     to: '/user/login-user',
                // },
                // {
                //     id: 29,
                //     component: CNavItem,
                //     name: '멀티 아이피 내역',
                //     to: '/user/multi-ip',
                // },
            ],
        },
        {
            id: 30,
            component: CNavGroup,
            name: '배팅 내역',
            to: '/betting',
            items: [
                {
                    id: 31,
                    component: CNavItem,
                    name: '통합 배팅내역',
                    to: '/betting/list',
                },
            ]
        },
        {
            id: 40,
            component: CNavGroup,
            name: '입/출금 관리',
            to: '/cash',
            items: [
                {
                    id: 41,
                    component: CNavItem,
                    name: '입금 신청 내역',
                    to: '/cash/in-request',
                },
                {
                    id: 42,
                    component: CNavItem,
                    name: '입금 내역 조회',
                    to: '/cash/in-list',
                },
                {
                    id: 43,
                    component: CNavItem,
                    name: '출금 신청 내역',
                    to: '/cash/withdraw-request',
                },
                {
                    id: 44,
                    component: CNavItem,
                    name: '출금 내역 조회',
                    to: '/cash/withdraw-list',
                },
                {
                    id: 45,
                    component: CNavItem,
                    name: '관리자 지급/회수',
                    to: '/cash/admin',
                },
                // {
                //     id: 46,
                //     component: CNavItem,
                //     name: '캐시 변동내역',
                //     to: '/cash/change-history',
                // },
            ]
        },
        {
            id: 50,
            component: CNavGroup,
            name: "정산 관리",
            to: '/settle',
            items: [
                {
                    id: 51,
                    component: CNavItem,
                    name: "파트너 정산내역",
                    to: '/settle/partner-list1'
                },
                // {
                //     id: 52,
                //     component: CNavItem,
                //     name: "파트너 정산내역2",
                //     to: '/settle/partner-list2'
                // },
                {
                    id: 53,
                    component: CNavItem,
                    name: "회원 정산내역",
                    to: '/settle/user-list'
                },
                {
                    id: 54,
                    component: CNavItem,
                    name: "게임별 정산 내역",
                    to: '/settle/game-list'
                },
                {
                    id: 55,
                    component: CNavItem,
                    name: "기간별 정산 내역",
                    to: '/settle/daily'
                },
                {
                    id: 56,
                    component: CNavItem,
                    name: "월 정산 내역",
                    to: '/settle/monthly'
                },
            ]
        },
        {
            id: 80,
            component: CNavGroup,
            name: "설정 관리",
            to: '/config',
            items: [
                {
                    id: 81,
                    component: CNavItem,
                    name: "사이트 설정",
                    to: '/config/site'
                },
                {
                    id: 82,
                    component: CNavItem,
                    name: "레벨별 설정",
                    to: '/config/level'
                },
                // {
                //     id: 83,
                //     component: CNavItem,
                //     name: "로그",
                //     to: '/config/log'
                // },
                {
                    id: 86,
                    component: CNavItem,
                    name: "점검 설정",
                    to: '/config/inspection'
                },
                // {
                //     id: 81,
                //     component: CNavItem,
                //     name: "이벤트 보너스 설정",
                //     to: '/config/event'
                // },
                // {
                //     id: 82,
                //     component: CNavItem,
                //     name: "은행 계좌 설정",
                //     to: '/config/default-bank'
                // },
                // {
                //     id: 83,
                //     component: CNavItem,
                //     name: "등급별 은행 설정",
                //     to: '/config/bank-grade'
                // },
                // {
                //     id: 84,
                //     component: CNavItem,
                //     name: "도메인 설정",
                //     to: '/config/domain'
                // },
                // {
                //     id: 85,
                //     component: CNavItem,
                //     name: "팝업 설정",
                //     to: '/config/popup'
                // },
                // {
                //     id: 87,
                //     component: CNavItem,
                //     name: "옵션 설정",
                //     to: '/config/option'
                // },
                // {
                //     id: 88,
                //     component: CNavItem,
                //     name: "차단 설정",
                //     to: '/config/block'
                // },
            ],
        },
        {
            id: 60,
            component: CNavGroup,
            name: "게임 관리",
            to: '/game',
            items: [
                {
                    id: 61,
                    component: CNavItem,
                    name: "스포츠 관리",
                    to: '/game/sports'
                },
            ],
        },
        {
            id: 70,
            component: CNavGroup,
            name: "게시판 관리",
            to: '/customer',
            items: [
                {
                    id: 73,
                    component: CNavItem,
                    name: "공지사항",
                    to: '/customer/notice'
                },
                {
                    id: 72,
                    component: CNavItem,
                    name: "이벤트 팝업",
                    to: '/customer/event'
                },
                {
                    id: 71,
                    component: CNavItem,
                    name: "쪽지 관리",
                    to: '/customer/memo'
                },
                // {
                //     id: 72,
                //     component: CNavItem,
                //     name: "1:1 문의 관리",
                //     to: '/customer/one-to-one'
                // },
                // {
                //     id: 74,
                //     component: CNavItem,
                //     name: "자주 묻는 질문",
                //     to: '/customer/faq'
                // },
            ],
        },
        // {
        //     id: 90,
        //     component: CNavGroup,
        //     name: "관리자 설정",
        //     to: '/admin',
        //     items: [
        //         {
        //             id: 91,
        //             component: CNavItem,
        //             name: "관리자 리스트",
        //             to: '/admin/list'
        //         },
        //         {
        //             id: 92,
        //             component: CNavItem,
        //             name: "관리자 접속 IP 관리",
        //             to: '/admin/ip-list'
        //         },
        //         {
        //             id: 93,
        //             component: CNavItem,
        //             name: "관리자 로그",
        //             to: '/admin/logs'
        //         },
        //     ],
        // },
    ];
}

export default Navigation;
